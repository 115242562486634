import { graphql } from 'gatsby';
import React from 'react';
import PropertyList from '../components/PropertyList';
import SEO from '../components/seo';

// This page is responsible for loading data from sanity, then passes it along to PropertyList
export default function PropertiesPage({ data }) {
  const locations = data.locations.nodes

  return (
    <div className="properties-page">
      <SEO title="Properties" />
      <h2 className="margin-bottom margin-top h2-bg">Our Properties</h2>
      {/* REVIEW do we need to add keywords here for SEO or is it fine as is? */}
      {/* <p>Some descriptive text for SEO purposes</p> */}
      <PropertyList properties={locations} />
    </div>
  )
}

export const query = graphql`
  query PropertyQuery {
    locations: allSanityLocation(sort: {order: ASC, fields: sortOrder}) {
      nodes {
        id
        name
        address
        sortOrder
        slug {
          current
        }
        apartments {
          id
          name
          available
          mainprice
          highprice
          sortOrder
          floorplan {
            asset {
              fluid(maxWidth: 1000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          images {
            asset {
              fluid(maxWidth: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
